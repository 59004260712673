<template>
  <vs-card>
    <div id="data-list-list-view" class="data-list-container">
      <div class="lg:hidden">
        <vs-button v-if="showCTA" @click="createPatientDetailsDrawer=true" color="primary" >Create Patient</vs-button>
        <div class="mb-2 pt-2 pr-2 flex flex-no-wrap">
          <div class="w-full pr-1">
            <vs-input
              icon="search"
              style="width: 100%"
              placeholder="Search"
              v-model="searchInputString"
            />
          </div>
          <div class="w-24 pl-1">
            <vs-select
              style="width: 100%"
              placeholder="10"
              autocomplete
              vs-multiple
              v-model="dataTableParams.limit"
              class="mr-6"
            >
              <vs-select-item
                :key="index"
                :value="item.value"
                :text="item.text"
                v-for="(item, index) in limitOptions"
                :clearable="false"
              />
            </vs-select>
          </div>
          <div class="w-24 pl-1">
            <vs-select
              v-model="dataTableParams.clinicId"
              placeholder="All Clinic"
              autocomplete
              class="mr-6"
            >
              <vs-select-item
                :key="index"
                :value="item.clinicId"
                :text="item.name"
                v-for="(item, index) in clinics"
              />
            </vs-select>
          </div>
        </div>
        <ul>
          <li :key="indextr" v-for="(tr, indextr) in patients">
            <div class="rounded mb-2 p-3" style="border: 1px solid #e8e8e8">
              <div class="flex pb-2">
                <h4 class="mr-2 mobile-text">
                  {{
                    patients[indextr].firstName
                      ? patients[indextr].firstName
                      : "N/A" | capitalize
                  }}
                </h4>
                <h4 class="mobile-text pb-2">
                  {{
                    patients[indextr].lastName
                      ? patients[indextr].lastName
                      : "N/A" | capitalize
                  }}
                </h4>
              </div>
              <div>
                <p class="mobile-text pb-2">
                  Gender: {{ patients[indextr].gender || "N/A" }}
                </p>
              </div>
              <div>
                <p class="mobile-text pb-2">
                  DOB: {{ patients[indextr].dateOfBirth || "N/A" }}
                </p>
              </div>
              <div>
                <p class="mobile-text pb-2">
                  Contact Number: {{ patients[indextr].contactNumber || "N/A" }}
                </p>
              </div>
              <div>
                <p class="mobile-text pb-2">
                  Adress: {{ patients[indextr].address || "N/A" }}
                </p>
              </div>
              <div class="flex">
                <vs-button
                  type="border"
                  size="small"
                  @click="viewPatientDetail(patients[indextr]._id)"
                  icon-pack="feather"
                  class="mr-2"
                  >View</vs-button
                >
              </div>
            </div>
          </li>
        </ul>
      </div>
      <!-- @change-page="handleChangePage"
      @sort="handleSort"
      :total="totalDocs" -->
      <vs-table
        class="hidden lg:inline"
        ref="table"
        :sst="true"
        :data="patients"
        @search="search"
        :max-items="dataTableParams.limit"
        search
        >
        <!-- :noDataText="noDataText" -->
        <div
          slot="header"
          class="flex flex-no-wrap flex-grow items-center justify-between mr-2"
        >
          <div class="flex">
            <div>
            <vs-select
              placeholder="10"
              vs-multiple
              autocomplete
              v-model="dataTableParams.limit"
              class="mr-6"
            >
              <vs-select-item
                :key="index"
                :value="item.value"
                :text="item.text"
                v-for="(item, index) in limitOptions"
                :clearable="false"
              />
            </vs-select>
          </div>
          <div>
            <!-- placeholder="All Clinic" -->
            <vs-select
              v-model="dataTableParams.clinicId"
              autocomplete
              class="mr-6"
            >
              <vs-select-item
                :key="index"
                :value="item.clinicId"
                :text="item.name"
                v-for="(item, index) in clinics"
              />
            </vs-select>
          </div>
          </div>
          <div class="justify-self-end">
            <vs-button v-if="showCTA" @click="createPatientDetailsDrawer=true" color="primary" >Create Patient</vs-button>
          </div>
        </div>

        <template slot="thead">
          <vs-th sort-key="firstName">Full Name</vs-th>
          <vs-th sort-key="email">Email</vs-th>
          <vs-th sort-key="gender">Gender</vs-th>
          <vs-th sort-key="dateOfBirth">DOB</vs-th>
          <vs-th sort-key="address">Address</vs-th>
          <vs-th sort-key="contactNumber">Contact Number</vs-th>
          <!-- <vs-th>Profile Image</vs-th> -->
          <vs-th sort-key="createdAt">Created At</vs-th>
          <!-- <vs-th sort-key="status">Status</vs-th> -->
          <vs-th size="25%">Actions</vs-th>
        </template>

        <template slot-scope="{ data }">
          <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
            <vs-td :data="data[indextr].firstName">
              {{ data[indextr].fullname || `${data[indextr].firstName} ${data[indextr].lastName}` }}
            </vs-td>
            <vs-td :data="data[indextr].email">
              {{ data[indextr].email }}
            </vs-td>
            <!-- <vs-td :data="data[indextr].lastName">
              {{ data[indextr].lastName }}
            </vs-td> -->
            <vs-td :data="data[indextr].gender">{{
              data[indextr].gender | capitalize
            }}</vs-td>
            <vs-td :data="data[indextr].dateOfBirth">
              {{ data[indextr].dateOfBirth | date_formatter }}
            </vs-td>
            <vs-td :data="data[indextr].address">
              {{ data[indextr].address }}
            </vs-td>
            <vs-td :data="data[indextr].contactNumber">
              {{ data[indextr].contactNumber }}
            </vs-td>
            <!-- <vs-td :data="data[indextr].profileImage">{{ data[indextr].profileImage }}</vs-td> -->
            <vs-td :data="data[indextr].createdAt">{{
              data[indextr].createdAt | date_formatter
            }}</vs-td>
            <!-- <vs-td :data="data[indextr].status">{{ data[indextr].status }}</vs-td> -->
            <vs-td :data="data[indextr]._id">
              <vs-button
                type="border"
                size="small"
                @click="viewPatientDetail(data[indextr]._id)"
                icon-pack="feather"
                class="mr-2"
                >View</vs-button
              >
            </vs-td>
          </vs-tr>
        </template>
      </vs-table>
    </div>
    <SideDrawerCreatePatient
      :selectedClinic="dataTableParams.clinicId"
      :createPatientDetailsDrawer="createPatientDetailsDrawer"
      @closeSideDrawer="closeSideDrawer"
      @refetchPatientDetail="refetchPatientDetail"
    />
  </vs-card>
</template>

<script>
import { search } from "core-js/fn/symbol";
import { debounce } from "lodash";
import vSelect from "vue-select";
import { created } from "vue-simple-suggest";
import { mapActions } from "vuex";
import SideDrawerCreatePatient from "@/views/general-component/treatment-history/SideDrawerCreatePatient.vue";

export default {
  name: "PatientList",
  components: {
    "v-select": vSelect,
    SideDrawerCreatePatient
  },
  props: ["patients", "totalDocs", "page", "show"],
  data() {
    return {
      searchInputString: '',
      clinics: [],
      createPatientDetailsDrawer: false,
      dataTableParams: {
        fullName: "",
        page: 1,
        limit: 25,
        clinicId: null,
      },
      limitOptions: [
        { text: "5", value: 5 },
        { text: "10", value: 10 },
        { text: "25", value: 25 },
        { text: "50", value: 50 },
        { text: "100", value: 100 },
      ],
      serverResponded: false,
    };
  },
  computed: {
    showCTA() {
      return JSON.parse(localStorage.getItem(`${process.env.VUE_APP_ACCESS_TOKEN_NAME}User`)).doctorEMRClinics.length > 0;
    },
  },
  methods:{
    ...mapActions("organization", [
      "fetchOrganizationClinics",
      "fetchOrganizationAllClinics",
    ]),
    refetchPatientDetail() {
      this.dataTableParams.search = null;

      this.$emit("fetchList", this.dataTableParams);
    },
    closeSideDrawer() {
      this.createPatientDetailsDrawer = false;
    },
    async fetchList() {
      this.$vs.loading();
      await this.$emit("fetchList", this.dataTableParams);
      this.serverResponded = true;
      this.$vs.loading.close();
    },
    viewPatientDetail(id) {
      this.$emit("viewPatientDetail", id);
    },
    search(searching) {
      this.searchInputString = searching;
    },
    async getOrganizationAllClinicsList(organizationId) {
      await this.fetchOrganizationAllClinics(organizationId).then((res) => {
        this.$vs.loading.close();
        this.clinics = [
          ...res.data.data,
        ];
        this.noDataText = "No Clinics Available";
      });
    },
    async callSearch(search) {
      this.$vs.loading();
      await this.handleSearch(search,this);
      this.serverResponded = true;
      this.$vs.loading.close();
    },
    handleSearch: debounce(async (search,self) => {
      await self.$emit(
        "searchPatients",
        {
          ...self.dataTableParams,
          search: search,
        }
      );
    }, 500),
  },
  watch: {
    "searchInputString": function (newVal, oldVal) {
      if (newVal && newVal !== oldVal) {
        this.callSearch(newVal);
      }
      else if (newVal.length === 0 && oldVal.length !== 0) {
        this.fetchList();
      }
    },
    "dataTableParams.page": function (newVal, oldVal) {
      if (this.searchInputString  && newVal !== oldVal) {
        this.callSearch(this.searchInputString);
      }
      else if (this.searchInputString.length === 0 && newVal !== oldVal) {
        this.fetchList();
      }
    },
    "dataTableParams.limit": function (newlimit, oldLimit) {
      if (this.searchInputString && newlimit !== oldLimit) {
        this.callSearch(this.searchInputString);
      }
      else if (this.searchInputString.length === 0 && newlimit !== oldLimit) {
        this.fetchList();
      }
    },
    "dataTableParams.clinicId": function (newVal, oldVal) {
      if (this.searchInputString && newVal !== oldVal) {
        this.callSearch(this.searchInputString);
      }
      else if (this.searchInputString.length === 0 && newVal !== oldVal) {
        this.fetchList();
      }
    }
  },
  async created() {
    await this.getOrganizationAllClinicsList(localStorage.getItem(
      `${process.env.VUE_APP_ACCESS_TOKEN_NAME}OrganizationId`
    ))
    // setTimeout(() => {
      this.dataTableParams.clinicId = this.clinics[0].clinicId;
    // }, 1000);
    this.fetchList();
  }
};
</script>
<style scoped>
.mobile-text {
  color: #164d3d;
}
.mobile-text {
  color: #164d3d;
}
.vs-dropdown-menu {
  z-index: 99999;
}
</style>
