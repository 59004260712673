<template>
  <div>
    <PatientList
      :patients="patients"
      :totalDocs="totalDocs"
      :page="page"
      @fetchList="getPatientsList"
      @searchPatients="searchPatients"
      @viewPatientDetail="patientDetail"
    />
  </div>
</template>

<script>
import { mapActions } from "vuex";
import PatientList from "../../components/patients/List2";

export default {
  components: {
    PatientList
  },
  data() {
    return {
      totalDocs: 0,
      page: 1,
      patients: []
    };
  },
  methods: {
    ...mapActions("doctor", ["fetchEmrPatientsListFilter", "searchEmrPatientsListFilter"]),
    async getPatientsList(dataTableParams) {
      try {
        this.$vs.loading();
        const res = await this.fetchEmrPatientsListFilter(dataTableParams);
        this.patients = res.data.data;
        this.$vs.loading.close();
      } catch (error) {
        console.log(error)
        this.$vs.loading.close();
        this.$vs.notify({
          title: "Error",
          text: "Error fetching patients",
          color: "danger"
        });
      }
    },
    async searchPatients(dataTableParams) {
      try {
        this.$vs.loading();
        const res = await this.searchEmrPatientsListFilter(dataTableParams);
        this.patients = res.data.data.docs;
        this.$vs.loading.close();
      } catch (error) {
        console.log(error)
        this.$vs.loading.close();
        this.$vs.notify({
          title: "Error",
          text: "Error fetching patients",
          color: "danger"
        });
      }
    },
    patientDetail(id) {
      this.$router.push({
        name: "DoctorPatientDetail",
        params: { patientId: id }
      });
    }
  }
};
</script>
