<template>
  <div>
    <vs-sidebar position-right parent="body" default-index="1" class="sidebarx" id="createPatientDetailsDrawer" spacer :click-not-close="true"
        v-model="showDrawer">
        <div class="headerClass space-y-3">
          <p class="text-xl font-semibold px-4 pt-4">Create Patients</p>
          <vs-divider></vs-divider>
        </div>
        <div class="overflow-y-auto px-4 pt-5 pb-20 flex flex-col gap-2 h-full" >
            <vs-input
                :success="!errors.first('firstName')"
                :danger="errors.first('firstName') ? true : false"
                :danger-text="errors.first('firstName')"
                val-icon-success="done"
                val-icon-danger="error"
                v-validate="'required|max:150'"
                name="firstName"
                data-vv-as="first name"
                label="First Name"
                class="w-full"
                v-model="patientDetails.firstName" />
            <vs-input
                :success="!errors.first('LastName')"
                :danger="errors.first('LastName') ? true : false"
                :danger-text="errors.first('LastName')"
                val-icon-success="done"
                val-icon-danger="error"
                v-validate="'required|max:150'"
                name="LastName"
                data-vv-as="last name"
                label="Last Name"
                class="w-full"
                v-model="patientDetails.lastName" />
            <div>
              <label class="vs-input--label">Clinic</label>
              <vs-select
                v-model="clinicId"
                placeholder="Select Clinic"
                autocomplete
                class="w-full"
              >
                <vs-select-item
                  :key="index"
                  :value="item._id"
                  :text="item.clinicName"
                  v-for="(item, index) in clinicsList"
                />
              </vs-select>
            </div>
            <div :class="{'input-icon-validate-success': !errors.first('dateOfBirth'), 'input-icon-validate-danger': errors.first('dateOfBirth')}">
              <label class="vs-input--label">Date of Birth</label>
              <Datepicker format="dd/MM/yyyy" :minimumView="'day'" :maximumView="'year'"
                :initialView="'year'" :disabled-dates="disabledDatesDOB" v-model="dateOfBirthModel" :input-class="{
                  'is-true vs-input--input':
                    !errors.has('dateOfBirth'),
                  'input-icon-validate-danger vs-input--input': errors.has('dateOfBirth'),
                }">
                  <template slot="afterDateInput">
                  <span v-if="!errors.has('dateOfBirth')" class="bg-color-done input-icon-validate vs-input--icon-validate">
                    <i valiconpack="material-icons" class="vs-icon notranslate icon-scale material-icons null">done</i>
                  </span>
                  <span v-if="errors.has('dateOfBirth')" class="bg-color-error input-icon-validate vs-input--icon-validate">
                    <i valiconpack="material-icons" class="vs-icon notranslate icon-scale material-icons null">error</i>
                  </span>
                </template>
                </Datepicker>
                <small class="text-danger" style="font-size: 0.75em" v-if="errors.has('dateOfBirth')">
                  {{ errors.first("dateOfBirth") }}
                </small>
            </div>

            <div class="flex w-full">
              <div class="w-full">
                <div style="margin-top: 13px">
                  <label
                    style="display: block"
                    class="vs-input--label custom-label mb-1"
                  >
                    Gender
                  </label>
                  <vs-radio
                    vs-value="male"
                    vs-name="radios1"
                    style="margin-right: 20px"
                    v-model="patientDetails.gender"
                    >Male</vs-radio
                  >
                  <vs-radio
                    vs-value="female"
                    vs-name="radios1"
                    style="margin-right: 20px"
                    v-model="patientDetails.gender"
                    >Female</vs-radio
                  >
                  <vs-radio
                    vs-value="other"
                    vs-name="radios1"
                    v-model="patientDetails.gender"
                    >Other</vs-radio
                  >
                  <span
                    v-if="errors.first('gender')"
                    style="display: block"
                    class="text-danger text-sm error-text no-margin-span"
                    >Please select one option.</span
                  >
                </div>
              </div>
            </div>
            <div>
              <small>Address</small>
              <div class="create-patient-address" id="geocoder" />
            </div>
            <vs-textarea
                :success="!errors.first('address')"
                :danger="errors.first('address') ? true : false"
                :danger-text="errors.first('address')"
                v-validate="'required'"
                readonly
                val-icon-success="done"
                val-icon-danger="error"
                data-vv-as="address"
                name="address"
                :class="'w-full' + (errors.has('address') ? ' mb-0' : '')"
                v-model="patientDetails.address" />
              <small class="text-danger mb-2" style="font-size: 0.75em" v-if="errors.has('address') && patientDetails.address != ''">
                {{ errors.first("address") }}
              </small>
            <vs-input
                :success="!errors.first('phoneNumber')"
                :danger="errors.first('phoneNumber') ? true : false"
                :danger-text="errors.first('phoneNumber')"
                val-icon-success="done"
                val-icon-danger="error"
                v-validate="'required|max:150'"
                data-vv-as="phoneNumber"
                name="phoneNumber"
                label="Phone Number"
                class="w-full"
                v-model="patientDetails.contactNumber" />
            <vs-input
                :success="!errors.first('email')"
                :danger="errors.first('email') ? true : false"
                :danger-text="errors.first('email')"
                val-icon-success="done"
                val-icon-danger="error"
                v-validate="'required|email'"
                name="email"
                data-vv-as="email"
                label="Email"
                class="w-full"
                v-model="patientDetails.email" />
            <div class="mb-4">
                <p class="text-sm">Allergies (optional)</p>
                <vs-button class="w-full" color="primary" type="border" @click="addAllergy()">Add Allergy</vs-button>
                <div v-for="(allergy, index) in patientDetails.allergies" :key="`allergy` + index">
                    <div class="flex gap-2 mt-2">
                        <vs-input name="allergies" data-vv-as="allergies" class="w-full"
                            v-model="patientDetails.allergies[index]" />
                        <vs-button size="medium" icon-pack="feather" icon="icon-trash" color="danger" @click.stop="deleteAllergies(index)" />
                    </div>
                </div>
            </div>
            <div class="mb-4">
                <p class="text-sm">Regular Medication (optional)</p>
                <vs-button class="w-full" color="primary" type="border" @click="addRegularMedication()">Add Regular
                    Medication</vs-button>
                <div v-for="(regularMedication, index) in patientDetails.regularMedications"
                    :key="`regularMedication` + index">
                    <div class="flex gap-2 mt-2">
                        <vs-input name="regularMedication" data-vv-as="regularMedication" class="w-full"
                            v-model="patientDetails.regularMedications[index]" />
                        <vs-button size="medium" icon-pack="feather" icon="icon-trash" color="danger" @click.stop="deleteRegularMedication(index)" />
                    </div>
                </div>
            </div>
            <div class="mb-4">
                <p class="text-sm">Previous Medical Diagnosis (optional)</p>
                <vs-button class="w-full" color="primary" type="border" @click="addPreviousMedicalDiagnosis()">Add
                    Previous Medical Diagnosis</vs-button>
                <div v-for="(previousMedicalDiagnose, index) in patientDetails.previousMedicalDiagnoses"
                    :key="`previousMedicalDiagnose` + index">
                    <div class="flex gap-2 mt-2">
                        <vs-input name="previousMedicalDiagnosis" data-vv-as="previousMedicalDiagnosis" icon-no-border
                            class="w-full" v-model="patientDetails.previousMedicalDiagnoses[index]" />
                        <vs-button size="medium" icon-pack="feather" icon="icon-trash" color="danger" @click.stop="deletePreviousMedicalDiagnosis(index)" />
                    </div>
                </div>
            </div>
        </div>
        <div class="p-4 flex flex-col gap-3" slot="footer">
          <vs-button @click="handleUpdatePatientDetails" :disabled="isCreatingPatient">
              Create Patient
          </vs-button>
          <vs-button type="border" class="p-2" @click.stop="closeSideDrawer">
              Cancel
          </vs-button>
        </div>
    </vs-sidebar>
  </div>
</template>
<script>
import { mapActions } from 'vuex';
import mapboxgl from "mapbox-gl";
import Datepicker from "vuejs-datepicker";
import MapboxGeocoder from '@mapbox/mapbox-gl-geocoder';
import '@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css';
import moment from 'moment';
import { cli } from 'tailwindcss/lib/constants';
import { type } from 'vuesax';
import { watch } from 'vue';
import { create } from 'lodash';

export default {
  name: "SideDrawerCreatePatient",
  props: {
    selectedClinic:{
      type: String,
      default: null,
    },
    createPatientDetailsDrawer: {
      type: Boolean,
      default: false,
    }
  },
  components: {
    Datepicker
  },
  data() {
    return {
      accessToken: process.env.VUE_APP_MAPBOX_API_KEY,
      region: process.env.VUE_APP_REGION,
      patientDetails: {
        email: "",
        dateOfBirth: "",
        allergies: [],
        regularMedications: [],
        previousMedicalDiagnoses: [],
        address: "",
        lastName: "",
        contactNumber: "",
        firstName: "",
        gender: "",
      },
      clinicId: null,
      isCreatingPatient: false,
      showDrawer: false,
      clinicsList: [],
      disabledDatesDOB: { from: new Date() },
    }
  },
  computed: {
    dateOfBirthModel: {
      get() {
        return this.patientDetails.dateOfBirth;
      },
      set(val) {
        // check if user is 18 years old or above
        const isUnderEighteen = moment(new Date(val)).format("YYYY-MM-DD") >
        moment().subtract(18, "years").format("YYYY-MM-DD")

        if (isUnderEighteen) {
          this.errors.add({
            field: "dateOfBirth",
            msg: "You must be at least 18 years old.",
          });
          this.isCreatingPatient = true;
          return;
        }
        this.patientDetails.dateOfBirth = val
        this.errors.remove("dateOfBirth");
        this.isCreatingPatient = false;
      },
    },
  },
  methods: {
    closeSideDrawer() {
      this.errors.items = [];
      this.patientDetails = {
        email: "",
        dateOfBirth: "",
        allergies: [],
        regularMedications: [],
        previousMedicalDiagnoses: [],
        address: "",
        lastName: "",
        contactNumber: "",
        firstName: "",
        gender: "",
      },
      this.errors.remove("dateOfBirth");
      this.isCreatingPatient = false
      this.$emit('closeSideDrawer')
    },
    async getClinicsList() {
      const response = await this.$store.dispatch('doctor/getUserEMRclinics',
      {organizationId: localStorage.getItem(`${process.env.VUE_APP_ACCESS_TOKEN_NAME}OrganizationId`)}
      )
      this.clinicsList = response.data.data;
    },
    addAllergy() {
        // TODO: replace with dynamic data
        this.patientDetails.allergies.push("")
    },
    addRegularMedication() {
        // TODO: replace with dynamic data
        this.patientDetails.regularMedications.push("")
    },
    addPreviousMedicalDiagnosis() {
        // TODO: replace with dynamic data
        this.patientDetails.previousMedicalDiagnoses.push("")
    },
    deletePreviousMedicalDiagnosis(index) {
        this.patientDetails.previousMedicalDiagnoses.splice(index, 1)
    },
    deleteAllergies(index) {
        this.patientDetails.allergies.splice(index, 1)
    },
    deleteRegularMedication(index) {
        this.patientDetails.regularMedications.splice(index, 1)
    },
    async handleUpdatePatientDetails() {
      let isValid = await this.$validator.validateAll(null);
      // check if user is 18 years old or above
      const isUnderEighteen = moment(new Date(this.patientDetails.dateOfBirth)).format("YYYY-MM-DD") >
          moment().subtract(18, "years").format("YYYY-MM-DD")
      if (
        !this.patientDetails.dateOfBirth
      ) {
        isValid = false;
        this.errors.add({
          field: "dateOfBirth",
          msg: "Date of Birth is required",
        });
      } else if (isUnderEighteen) {
          this.errors.add({
            field: "dateOfBirth",
            msg: "You must be at least 18 years old.",
          })
        } else {
        this.errors.remove("dateOfBirth");
      }
      this.isCreatingPatient = true;
      try {
        if (isValid) {
          const {data} = await this.$store.dispatch('patient/createPatient', {
            ...this.patientDetails,
            clinicId: this.clinicId
          })
          
          this.$vs.notify({
            title: "Patient created",
            text: "Patient's details created successfully.",
            color: "success",
          });
          this.isCreatingPatient = false;
          this.closeSideDrawer()
          this.$emit('refetchPatientDetail')

          await this.$nextTick()
          await this.$router.replace(`/doctor/patients/${data.data.patientData.id}/details`)
        }
        else {
          this.$vs.notify({
            title: "Failed",
            text: "Please fill all the required fields",
            color: "danger",
          });
          this.isCreatingPatient = false;
        }
      } catch (error) {
        console.log(error)
        this.isCreatingPatient = false;
        this.$vs.notify({
          title: "Failed",
          text: "Failed to create patient. Try again!",
          color: "danger",
        });
      }
    },
    regionType() {
      const regions = {
        US: "us",
        AU: "au",
        NZ: "nz"
      }
      return regions[this.region]
    },
    initMapboxGeocoder() {
      mapboxgl.accessToken = this.accessToken;

      let geocoder = new MapboxGeocoder({
        accessToken: mapboxgl.accessToken,
        types: "address",
        countries: this.regionType(),
        placeholder: this.patientDetails ? this.patientDetails.address : "",
      });

      // Ensure the element exists before adding the geocoder
      this.$nextTick(() => {
        const geocoderContainer = document.getElementById("geocoder");
        if (geocoderContainer) {
          geocoder.addTo(geocoderContainer);
        } else {
          console.error("Geocoder container not found");
        }
      });

      geocoder.on("results", (e) => {
        this.patientDetails.address = e.config.query;
      });
      geocoder.on("clear", () => {
        this.patientDetails.address = "";
      });

      geocoder.on("result", (e) => {
        this.patientDetails.address = e.result.place_name;
      });
    }
  },
  watch: {
    clinicsList: function (newVal) {
      if (newVal.length > 0 && newVal.filter(clinic => `${clinic._id}` === `${this.clinicId}`).length === 0) {
        this.clinicId = newVal[0]._id;
      }
    },
    createPatientDetailsDrawer: function (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.showDrawer = newVal;
      }
    },
    showDrawer: function (newVal, oldVal) {
      if(newVal !== oldVal && !newVal && this.createPatientDetailsDrawer) {
      this.closeSideDrawer();
      }
    },
    selectedClinic: function (newVal, oldVal) {
      if  (newVal !== oldVal && this.clinicsList.filter(clinic => `${clinic._id}` === `${newVal}`).length === 0) {
        this.clinicId = this.clinicsList[0]._id;
      }
      else{
        this.clinicId = newVal;
      }
    }
  },
  mounted() {
    this.initMapboxGeocoder();
  },
  created() {
    this.getClinicsList();
  }
}
</script>
<style>

.vs-sidebar--background {
  z-index: 52000 !important;
}

.sidebarEditPatientDetailsDrawer {
  .vs-sidebar-position-right {
    max-width: 350px;
  }
}

.vs-sidebar.vs-sidebar-position-right {
  z-index: 53000;
}

.sidebarx .vs-sidebar-primary {
  max-width: none;
}

@media (min-width: 768px) {
  .sidebarx .vs-sidebar-primary {
    max-width: 350px;
  }
}
.create-patient-address .mapboxgl-ctrl-geocoder{
  width: 100%!important;
}

</style>
